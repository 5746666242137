.ie9 img[src$=".svg"] {
  width: 100%; 
}
/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%; 
  }
}
.mapsvg {
    height: 100px;
    position : relative;
    background: transparent !important;
    //max-width: 1000px;
    margin: 30px auto;
}
.mapsvg {
    //overflow: hidden;
}
.mapsvg svg {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mapsvg.mapsvg-responsive {
    width: 100%;
    height: auto;
}
.mapsvg.mapsvg-cursor-pointer .mapsvg-region {
    cursor: pointer;
}
.mapsvg-loading {
    position: absolute;
    top:  50%;
    left: 50%;
    font-size: 14px !important;
    z-index: 1;
    padding: 7px 10px;
    background: #35845a;
    color: #ffffff;
}
.mapsvg-disabled {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    pointer-events: none;
    cursor: default;
}
.mapsvg-buttons {
    position: absolute;
    top: 15px;
    width: 16px;
    right: 15px;
}
.mapsvg-buttons.right {
    right: 15px;
}
.mapsvg-buttons.left {
    left: 15px;
}
.mapsvg-btn-zoom {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    display: block;
    margin-bottom: 7px;
    cursor: pointer;
    background-repeat: no-repeat;
}

.mapsvg-btn-zoom.in {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABhElEQVR4nJWTT4rqQBDGf92pSEJWmYfgQpABb+EB1NU8DyBe5M1q5iKStTCDd/AWggElC3EQJAQxbb/NJDH+mccraEh31fdVfR8pBRBF0Uuapn+AX8CZn0MDuyAI3sfj8aeaTqcvWZZ9XFdZazmdTgC4rotS6oYpCILfkmXZ6yNwt9tFKcVyucRxnBuSNE1fNfB0TWCModlsMhwOGQwGdDod8jy/J+dJP9JsjKl9W2vvlZ3lcuyiS57ntY7FvZDgum6Zk0vN7XYbay3GGMIwLItarRbGGEQErTVxHON5XkVQAEaj0b0x6fV6tXsURRwOBxzHQd9F/CPO58o2ARARdrsds9ms9CIMQ/r9PgCLxYL1eo3rulhr2e/3dQkAnueRJElp2vF4LLskScJmsynNK8A1AqjcVUohUqVEBBGpuV+E/j63CV093/sLizIBvoDny1fHcdhut8znc5RSrFar2kQX8aV933+7ZldK0Wg0iOO4BD9YpjcF8L2R/7XOvu+/TyaTz79+UqnWsVHWHAAAAABJRU5ErkJggg==)
}
.mapsvg-btn-zoom.out {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAA6klEQVR4nKWTPW6DQBBG3w4RaLXSFs4puAe9fQHEReLKPgYN4gLxQei5RNytFraANNEKKwk29uum+N78SKMA2rbdO+c+gHdgYh0Bvowx57IsL6ppmr33/vNO6E+MMQfx3h+fCQM4544C7J4VADvh/s5rTG/LKoTANK37RIQ0TWMdBSEE8jwnyzLmef437L2n7/soiQLnHEVRPDR313VRIA8lVogTWGup6/pmhSRJAFBKxcAwDFhrfwuSJCGEwDiOqx2VUlF8I1h23ILw2h1EgOsLgqtorU/LI23BGHNSAD8fuemdtdbnqqou39SbTK6RdYDsAAAAAElFTkSuQmCC)
}
.mapsvg-tooltip {
    font-weight: normal;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: #ffffff;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background-color: #ca264c;
    border-bottom: 5px solid #ffffff;
    padding: 15px 40px;
    max-width: 600px;
    opacity: 0;
    -webkit-transition: opacity .15s ease-in;
    -moz-transition: opacity .15s ease-in;
    -ms-transition: opacity .15s ease-in;
    -o-transition: opacity .15s ease-in;
    transition: opacity .15s ease-in;
}
.mapsvg-tooltip.mapsvg-tooltip-visible {
    opacity: 1;
}
/*
.mapsvg-popover {
    font-weight: normal;
    font-size: 12px;
    font-family: Helvetica, Arial;
    color: #000000;
    position: absolute;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    top: 0;
    left: 0;
    z-index: 1000;
    width: auto;
    height: auto;
    max-width: 400px;
    max-height: 300px;
    overflow: scroll;
    display: none;
    background-color: white;
    border: 1px solid #ccc;
    padding: 17px 12px 12px 12px;
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.2);
}*/
.mapsvg-popover .mapsvg-popover-close {
    position: absolute;
    top: -3px;
    right : 3px;
    cursor: pointer;
    color: #aaa;
    z-index : 1200;
    font-size: 14px;
    font-weight: normal;
}
.mapsvg-popover .mapsvg-popover-content {}


.mapsvg-gauge {
    background-color: #ffffff;
    border-radius: 3px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    max-width: 30%;
    z-index: 1000;
    font-size: 11px !important;
    line-height: 15px !important;
    color: #666666;
    padding: 0px 0px;
    font-weight: normal;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.5);
}
.mapsvg-gauge table {
    width: auto;
    margin: 0;
    border: 0;
    padding: 0;

}

.mapsvg-gauge .mapsvg-gauge-gradient {
    width: 150px;
}

.mapsvg-gauge table tr {
    border :0 !important;
}
.mapsvg-gauge table td {
    padding: 2px 7px;
    border: 0 !important;
}

.mapsvg-marker {
    cursor: pointer;
}
/*
.mapsvg-marker {
    -webkit-transition: -webkit-transform .1s ease-out;
    -moz-transition: -moz-transform .1s ease-out;
    -ms-transition: -ms-transform .1s ease-out;
    -o-transition: -o-transform .1s ease-out;
    transition: transform .1s ease-out;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
@-webkit-keyframes bounce {
    20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
    0% {-webkit-transform: translateY(-25px);}
    30% {-webkit-transform: translateY(-10px);}
}

@keyframes bounce {
    20%, 50%, 80%, 100% {transform: translateY(0);}
    0% {transform: translateY(-25px);}
    30% {transform: translateY(-10px);}
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}
*/
/*
.mapsvg .mapsvg-region {
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -ms-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
}
*/
.mapsvg text, .mapsvg tspan {
    pointer-events: none;
}

.mapsvg-popover {
    background-color: rgba(228,228,228, 0.9);
    border-radius: 0;
    /*bottom: 42px;*/
    color: #000;
    display: none;
    font-size: 12px;
    font-weight: normal;
    /*left: -95px;*/
    padding: 15px 10px 20px 0px;
    position: absolute;
    max-width: 570px;
    min-width: 150px;
    min-height: 80px; 
    z-index: 1000;
    margin-top: -25px;
    margin-left: 44px;
    
    .prop-title {
        padding-left: 25px;
        padding-right: 15px;
        max-width: 300px;
    }
    
    .street,
    .city-state,
    .prop-type,
    .vacant-sf,
    .total-sf,
    .explore,
    .download {
        margin-left: 25px;
    }
    
    .prop-img-wrap {
        height: 175px;
        overflow: hidden;
    }
    
    .prop-img {
        max-width: 300px;
        padding-left: 10px;
    }
    
    .prop-title {
        background: #3dac71;
        color: #ffffff;
        font-weight: 600;
        font-size: 25px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    .street {
        font-weight: 300;
        font-size: 25px;
        padding-top: 15px;
        max-width: 275px;
    }
    
    .city-state {
        font-weight: 300;
        font-size: 16px;
        max-width: 275px;
    }
    
    .prop-type {
        text-transform: capitalize;
        font-weight: 400;
        font-size: 14px;
        padding-top: 2px;
        padding-bottom: 2px;
    }
    
    .vacant-sf,
    .total-sf {
        color: #00a651;
        font-weight: 700;
        font-size: 15px;
    }
    
    .total-sf {
        padding-top: 5px; 
    }
    
    .vacant-sf {
        padding-bottom: 5px; 
    }
    
    .explore,
    .download {
        display: block;
        font-weight: 400;
        color: #00a651;
        font-size: 14px;
        padding-top: 2px;
        padding-bottom: 2px;
        text-indent: 25px;
        position: relative;
    }
    
    .explore {
        padding-top: 5px;
    }
    
    .popup-info {
        float: left;
    }
    
    .popup-map {
        width: 250px;
        margin-left: 10px;
        margin-top: 0px;
        background: url('../images/gmap-loader.svg') no-repeat center center;
        height: 434px;
        float: left;
        
        iframe {
            border: 0;
            border: none;
            width: 100%;
            height: 100%;
        }
    }
}
.mapsvg-popover:before {
    border-top: 22px solid rgba(228,228,228, 0.9);
    border-right:22px solid transparent;
    border-left:22px solid transparent;
    bottom: -22px;
    content: '';
    display: block;
    left: 32%;
    position: absolute;
}
.mapsvg-popover.mapsvg-popover-visible {
    display: block;
    -webkit-animation: fade-in .2s linear 1, move-up .2s ease-out;
    -moz-animation: fade-in .2s linear 1, move-up .2s ease-out;
    -ms-animation: fade-in .2s linear 1, move-up .2s ease-out;
}

@-webkit-keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-ms-keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes move-up {
    from {
        -webkit-transform: translateY(-30px);
    }
    to {
        -webkit-transform: translateY(0px);
    }
}
@-moz-keyframes move-up {
    from {
        -moz-transform: translateY(-30px);
    }
    to {
        -moz-transform: translateY(0px);
    }
}
@-ms-keyframes move-up {
    from {
        -ms-transform: translateY(-30px);
    }
    to {
        -ms-transform: translateY(0px);
    }
}

.mapsvg-menu li {
    list-style: none;
}